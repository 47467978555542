.alert-main {
    height: 40px;
    width: 300px;
    background-color: rgb(130, 170, 130);
    display: flex;
    flex-direction: row;
    border-radius: 7px;
    z-index: 2300;
}

.alert-contain {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.alert-text {
    color: white;
    font-weight: 600;
    margin-bottom: 0px;
    opacity: 0.8;
    font-size: 14px;
}

.alert-bar {
    height: 40px;
    width: 10px;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
    background-color: green;
}

.alert-closeText {
    color: rgba(255, 255, 255, 0.705);
    text-align: center;
    margin-bottom: 0px;
}

.alert-text-main {
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex: 1
}

.alert-close {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30px;
    background-color: rgb(87, 138, 87);
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;

}

.alert-icon {
    background-color: rgb(67, 116, 67);
    width: 10px;
    height: 10px;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    font-size: 20px;
    margin-top: 2px;
    margin-right: 10px;
}

.alert-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
}