    .navbartop-main {
      height: 40px;
      width: 100%;
      z-index: 1000;
      position: fixed;
      padding-left: 10px;
      background-image: linear-gradient(to right, #ef4123, #f0cb35);
      display: flex;
      flex-direction: row;
      -webkit-transition: padding-left 1s;
      /* Safari */
      transition: padding-left 1s;
    }

    .navbartop-main-full {
      height: 50px;
      width: 100%;
      box-shadow: 0px 2px 2px 2px #b6b6b6;
      padding-left: 90px;
      display: flex;
      flex-direction: row;
      -webkit-transition: padding-left 1s;
      /* Safari */
      transition: padding-left 1s;
    }

    .navbartop-header {
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
    }

    .navbartop-sidenav-icon {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      background-color: #303030;
      color: #ffff;
      font-size: 15px;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-right: 20px;
    }


    //profile view
    .sidebar-profile .profile-pic {
      height: 40px;
      width: 50px;
      overflow: hidden;
      border-radius: 100px;
      border: 1.5px solid rgb(136, 136, 136);
      -webkit-transition: height 1s, width 1s;
      /* Safari */
      transition: height 1s, width 1s;
    }

    .sidebar-profile {
      //margin-top: 10px;
      //padding-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: white;
      border-bottom: 1px solid rgb(138, 138, 138);
      -webkit-transition: height 1s, width 1s;
      /* Safari */
      transition: height 1s, width 1s;
    }

    .sidebar-profile-hide h6 {
      display: none;
    }

    .sidebar-profile-hide .profile-pic {
      height: 40px;
      width: 50px;
      overflow: hidden;
      border-radius: 50px;
      border: 1.5px solid rgb(136, 136, 136);
      -webkit-transition: height 1s, width 1s;
      /* Safari */
      transition: height 1s, width 1s;
    }