.OverallActivities{
    padding:10px;
    //border: 1px solid #ef4123;
    transition: 0.3s;
    margin-bottom:5px;
    background-color: white;
}


.OverallActivities_card{
    padding:10px;
    height: 250px;
}