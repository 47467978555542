.container-form{
    margin-top:20px;
    padding-left:10%;
    padding-right:10%;
    
}

.btn-submit{
    background-color: black;
    color:white;
    font-size: 13px;
    padding:3px 13px 3px 13px;
    border:0px;
    box-shadow: 0px 4px 4px 0px #b8b8b8;
}
.btn-submit:hover{
    box-shadow: 0px 6px 6px 0px #b8b8b8;

}

.container-form .row{
    padding-top:10px;
    font-size: 13px;
}

.container-form .form-control{

    &:focus{
        border-color: black;
        box-shadow: 0 0 0 1px rgba(0,0,0,.25)
    }

    &::placeholder{
        font-size: 10px;
    }
}

  //Add Project
  .container-form{
    background-color: white;
  }
  .container-form  p{
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.63px;
    color: #58595b;
    margin-top:10px;
    margin-bottom:5px;
  } 
  
  .container-form  input{
    border-radius: 5px ;
    border: solid 1px #979797;
  }
  .container-form  textarea{
    border-radius: 5px ;
    border: solid 1px #979797;
  }


