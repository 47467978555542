.CompletedProjectsActivity{
    padding:10px;
    //border: 1px solid #ef4123;
    transition: 0.3s;
    margin-bottom:5px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);

}


.CompletedProjectsActivity_card{
    padding:10px;
    height: 220px;
    overflow-y: scroll;
}