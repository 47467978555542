.sidebar-wrapper {
  width: 220px;
  height: calc(100%);
  margin-top: 40px;
  max-height: 100%;
  position: fixed;
  background-color: #000104;
  background-size: auto 100%;
  box-shadow: 0px 3px 3px 3px #888888;
  top: 0;
  left: 0px;
  z-index: 999;
  -webkit-transition: width 1s;
  /* Safari */
  transition: width 1s;
  overflow: hidden;
}

#sideNav .ant-menu-item-selected {
  background-color: rgb(239, 65, 35) !important;
}

.table-container {
  padding: 10;
  padding-left: 280
}

@media only screen and (max-width: 600px) {

  .table-container {
    padding: 10;
    padding-left: 100
  }

  .sidebar-wrapper {
    width: 60px;
    height: calc(100% - 100px);
    margin-top: 100px;
    max-height: 100%;
    position: fixed;
    background-image: url("../../assets/images/navbackground.jpeg");
    background-size: auto 100%;
    box-shadow: 0px 3px 3px 3px #888888;
    top: 0;
    left: 0px;
    z-index: 999;
    -webkit-transition: width 1s;
    /* Safari */
    transition: width 1s;
    overflow: hidden;
  }

  .sidebar-wrapper:hover {
    width: 260px;
    height: calc(100% - 100px);
    margin-top: 100px;
    max-height: 100%;
    position: fixed;
    background-image: url("../../assets/images/navbackground.jpeg");
    background-size: auto 100%;
    box-shadow: 0px 3px 3px 3px #888888;
    top: 0;
    left: 0px;
    z-index: 999;
    -webkit-transition: width 1s;
    /* Safari */
    transition: width 1s;
    overflow: hidden;

    .sidebar-profile-hide {
      margin-top: 20px;
      padding-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: white;
      border-bottom: 1px solid rgb(138, 138, 138);
      -webkit-transition: height 1s, width 1s;
      /* Safari */
      transition: height 1s, width 1s;

      .profile-pic {
        height: 80px;
        width: 80px;
        overflow: hidden;
        border-radius: 100px;
        border: 1.5px solid rgb(136, 136, 136);
        -webkit-transition: height 1s, width 1s;
        /* Safari */
        transition: height 1s, width 1s;
      }
    }
  }

}

#sidebar .faActive {
  color: #ffffff !important;
  font-size: 23px;
}

#sidebar .faInActive {
  color: #d3d3d3;
  font-size: 23px;

}

#sidebar .faInActive:hover {
  // display: none;
  color: #fc654a
}

.sidebar-wrapper-hide {
  width: 60px;
  height: calc(100% - 100px);
  margin-top: 100px;
  max-height: 100%;
  position: fixed;
  background-image: url("../../assets/images/navbackground.jpeg");
  background-size: auto 100%;
  box-shadow: 0px 3px 3px 3px #888888;
  top: 0;
  left: 0px;
  z-index: 999;
  -webkit-transition: width 1s;
  /* Safari */
  transition: width 1s;
  overflow: hidden;

}

.sidebar-wrapper-hide:hover {
  width: 260px;
  height: calc(100% - 100px);
  max-height: 100%;
  margin-top: 100px;
  position: fixed;
  background-image: url("../../assets/images/navbackground.jpeg");
  background-size: auto 100%;
  box-shadow: 0px 3px 3px 3px #888888;
  top: 0;
  left: 0px;
  z-index: 999;
  -webkit-transition: width 1s;
  /* Safari */
  transition: width 1s;
  overflow: hidden;

  .sidebar-profile-hide {
    margin-top: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    border-bottom: 1px solid rgb(138, 138, 138);
    -webkit-transition: height 1s, width 1s;
    /* Safari */
    transition: height 1s, width 1s;

    .profile-pic {
      height: 80px;
      width: 80px;
      overflow: hidden;
      border-radius: 100px;
      border: 1.5px solid rgb(136, 136, 136);
      -webkit-transition: height 1s, width 1s;
      /* Safari */
      transition: height 1s, width 1s;
    }
  }
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-content {
  background-color: #000104;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.sidebar-wrapper a {
  text-decoration: none;
}

//header
.sidebar-header {
  display: flex;
  padding: 10px;
  border-bottom: 1.5px solid rgb(138, 138, 138);
}

.sidebar-header-hide h4 {
  display: none;
}

.sidebar-header h4 {
  background: linear-gradient(to right, rgb(0, 183, 255), rgb(255, 187, 0), rgb(255, 187, 0));
  background-clip: text;


}

//links
.sidebar-links-content {
  margin-top: 40px;

}

.sidebar-links-content .fa {
  margin-right: 20px;

}


.sidebar-links-div {
  padding: 10px;

}

.sidebar-links-div a {
  display: flex;
  font-size: 14px;
  color: rgb(219, 219, 219);
  font-weight: 500;
}

.sidebar-links-div-drop {
  padding: 10px;
  margin-bottom: -9px;
}

.sidebar-links-div-drop p {
  display: flex;
  font-size: 14px;
  color: rgb(219, 219, 219);
  font-weight: 500;
  cursor: pointer;
}

.sidebar-links-div-drop-active p {
  display: flex;
  font-size: 14px;
  color: rgb(219, 219, 219);
  font-weight: 500;
  cursor: pointer;
}

.sidebar-links-div:hover {
  a {
    color: #fc654a;
  }

  icon {
    color: #fc654a;
  }
}

.sidebar-links-div-drop p:hover {
  a {
    color: #fc654a;
  }

  icon {
    color: #fc654a;
  }

  color:#fc654a;

}

.sidebar-links-div a icon {
  font-size: 16px;
  color: rgb(219, 219, 219);
  padding-right: 20px;
}

.sidebar-links-div-drop .link icon {
  font-size: 16px;
  color: rgb(219, 219, 219);
  //padding-right: 20px;
}

.sidebar-links-div .link {
  font-size: 15px;
  color: rgb(219, 219, 219);
  padding-right: 20px;
}

.sidebar-links-div-active {
  font-size: 15px;
  color: #fc654a;
  padding-right: 20px;
}

.sidebar-links-div-active a {
  display: flex;
  font-size: 14px;
  color: #fc654a;
  font-weight: 500;
}

.sidebar-links-div-active a icon {
  font-size: 16px;
  color: #fc654a;
  padding-right: 20px;
}


.sidebar-links-div-active .link {
  font-size: 15px;
  color: #fc654a;
  padding-right: 20px;
}

.active span {
  color: #ffffff;

}

.sidebar-links-content .active {
  background-color: #ef4123dd;
  width: 200px;
}

//profile view
.sidebar-profile .profile-pic {
  height: 80px;
  width: 80px;
  overflow: hidden;
  border-radius: 100px;
  border: 1.5px solid rgb(136, 136, 136);
  -webkit-transition: height 1s, width 1s;
  /* Safari */
  transition: height 1s, width 1s;
}

.sidebar-profile {
  margin-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  border-bottom: 1px solid rgb(138, 138, 138);
  -webkit-transition: height 1s, width 1s;
  /* Safari */
  transition: height 1s, width 1s;
}

.sidebar-profile-hide h6 {
  display: none;
}

.sidebar-profile-hide .profile-pic {
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 50px;
  border: 1.5px solid rgb(136, 136, 136);
  -webkit-transition: height 1s, width 1s;
  /* Safari */
  transition: height 1s, width 1s;
}



.sidebar-profile-hide {
  margin-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  border-bottom: 1px solid rgb(138, 138, 138);
  -webkit-transition: height 1s, width 1s;
  /* Safari */
  transition: height 1s, width 1s;
}

.sidebar-profile h6 {
  padding-left: 16px;
}

.fa-sort-down {
  font-size: 15px;
  text-align: right;
  margin-top: 2px;
  width: 100%;
}

.fa-sort-up {
  font-size: 15px;
  text-align: right;
  margin-top: 9px;
  width: 100%;
}

.sidebar-links-div-drop-inside {
  padding: 8px;
  padding-left: 45px;
}

.sidebar-links-div-drop-inside a {
  display: flex;
  font-size: 14px;
  color: rgb(219, 219, 219);
  font-weight: 500;
}

.sidebar-links-div-drop-inside:hover {
  color: #fc654a;

  a {
    color: #fc654a;
  }
}

.link-title-hide {
  // display: none;
  color: #d3d3d3
}

.link-title-hide:hover {
  // display: none;
  color: #fc654a
}


.r1 {
  margin: 15;
}

.marker,
.r,
.e1,
.s1,
.s2,
.s3,
.s4,
.s6,
.e2 {
  animation-timing-function: cubic-bezier(0.15, -0.01, 0.58, 1);
  animation-duration: 5s;
  animation-iteration-count: infinite;
  will-change: transform;
}

.marker {
  position: absolute;
  top: -10px;
  left: 0px;
  height: 3px;
  width: 20px;
  background-color: white;
  animation-name: marker;
}

.r {
  margin-left: 5px;
  animation-name: r;
}

.s3 {
  margin-left: 10px;
  animation-name: s3;
}

.s6 {
  margin-left: 10px;
  animation-name: s6;
}

.s2 {
  margin-left: 22px;
  animation-name: s2;
}

.s4 {
  margin-left: 20px;
  animation-name: s4;
}

.s5 {
  margin: 0 43px 0 25px;
  animation-name: s5;
}

@keyframes r {

  0%,
  20% {
    transform: translateX(0);
  }

  50%,
  70% {
    transform: translateX(20px);
  }
}

@keyframes s3 {

  0%,
  20% {
    transform: translateX(0);
  }

  50%,
  70% {
    transform: translateX(35px);
  }
}

@keyframes s6 {

  0%,
  20% {
    transform: translateX(0);
  }

  50%,
  70% {
    transform: translateX(40px);
  }
}

@keyframes s2 {

  0%,
  20% {
    transform: translateX(0);
  }

  50%,
  70% {
    transform: translateX(30px);
  }
}

@keyframes s4 {

  0%,
  20% {
    transform: translateX(0);
  }

  50%,
  70% {
    transform: translateX(20px);
  }
}

@keyframes s5 {

  0%,
  20% {
    transform: translateX(0);
  }

  50%,
  70% {
    transform: translateX(70px);
  }
}

@keyframes marker {

  0%,
  20% {
    transform: translateX(0);
  }

  50%,
  70% {
    transform: translateX(210px);
  }
}

.silder_header_title {
  margin: 0;
  color: white;
  width: 230px;
  font-family: 'Work Sans', sans-serif;
  font-size: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

main {
  position: relative;
  display: flex;
  width: 230px;
}