.rm {
    width: 100%;
    font-size: 12px;
}

.rm-title {
    background-color: #e7e7e7;
    width: 100%;
    padding: 10px;
    padding-bottom: 20px;
}

.rm-reportData {
    min-height: 60px;
}

.rm-noData {
    text-align: center;
    color: gray;
    font-size: small;
}

.rm-input {
    width: 100%;
}

.rm-option {
    font-size: 12px;
}

.rm-collapsePanel {
    font-size: 12px;
}