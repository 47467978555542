.ProjectsActivity{
    padding:10px;
    //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    //border: 1px solid #ef4123;
    transition: 0.3s;
    margin-bottom:5px;
    background-color: white;
    //@errorbackground-color: #fdcb9b;

}


.ProjectsActivity_card{
    
    padding:10px;
    height: 250px;
    overflow-y: scroll;

}

/* On mouse-over, add a deeper shadow */
.ProjectsActivity:hover {
 // box-shadow: 0 8px 16px 0 #ef4123;
 // border: 1px solid #ef4123;
}


.ProjectsActivity_card_tab{
    border: 1px solid #ef4123;
    border-radius: 5px;
    padding: 5px;
    margin-bottom:3px;
}

.ProjectsActivity_card_tab_Title{
    font-size: 15px;
    font-weight: 700;
    width:100%;
}

.ProjectsActivity_card_tab_date{
    font-size: 12px;
    color:rgb(80, 79, 79);
    width:50%;

}
.ProjectsActivity_card_tab_content{
    display: flex;
    flex-direction: row;
    text-align: center;
}

.ProjectsActivity_card_tab_tap{
    width:50%;
}
.ProjectsActivity_card_tab_tap_title{
    font-size: 12px;
    color:rgb(163, 161, 161);
}

.ProjectsActivity_card_tab_projectContent{
    border:1px solid  #ef4123;
    padding:5px;
    border-radius: 10px;
}

.ProjectsActivity_card_tab_projectContent:hover{
    box-shadow: 0 2px 4px 0 #f3745e;
    margin-top:-5px;
 
}

.ProjectsActivity_content{
    padding:10px;
    height: 250px;
    overflow-y: scroll;
}