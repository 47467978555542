@import "./forms.scss";

///home
.card-home {
  width: 100%;
  box-shadow: 0px 2px 2px 0px #b6b6b6;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  flex-direction: column;
}



#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
  overflow: visible !important;
}

.site-layout-background {
  background: #ffff;
}

.date-picker h6,
.project-status h6,
#pie_chart_div h5,
.card-presentage-delay h6,
.projectCard h6,
.navbartop-main h5 {
  color: white !important;
}


.content-div {
  // padding-left: 240px;
  -webkit-transition: padding-left 1s;
  /* Safari */
  transition: padding-left 1s;
}

.container {
  padding: 10px;
  padding-left: 240px
}

.table-container {
  padding: 5px;
  width: 100%;
}

@media only screen and (max-width: 600px) {

  .table-container {
    padding: 10px;
    padding-left: 100px
  }

  .container {
    padding: 10px;
    padding-left: 70px
  }

  .content-div {
    padding: 10px;
    padding-left: 70px;
    -webkit-transition: padding-left 1s;
    /* Safari */
    transition: padding-left 1s;
  }

  .navbartop-main {
    padding-left: 100px;

  }

  #root {
    width: calc(100%);
    overflow: hidden;

  }
}

.card-home h4 {
  font-size: 15px;
  font-weight: 800;

}

.card-home h1 {
  font-size: 60px;
  font-weight: 300;

}

.cart-content {
  margin-bottom: 10px;
}



.rt-resizable-header {
  background-color: #ef4123;
  color: white;

}

.rt-thead .rt-th {
  border: 1px solid white;

}



.-filters .rt-tr .rt-th input {
  border-radius: 15px;
  height: 30px;
}

.-filters .rt-tr .rt-th select {
  border-radius: 15px;
  height: 30px;
}

.rt-resizable-header-content {
  font-size: 13px;
}

.rt-td {
  font-size: 12px;
}

.rt-td:nth-child(3),
.rt-td:nth-child(4),
.rt-td:nth-child(5) {
  justify-content: center;
  display: flex;
}

.rt-td:nth-child(6),
.rt-td:nth-child(7) {
  justify-content: flex-end;
  display: flex;
}

.rt-td:nth-child(8) {
  padding-left: 20px;
  padding-right: 20px;
}

.modal {
  position: fixed;
  /* Stay in place */
  z-index: 1000;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  display: block;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9);
  /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

#img01 {
  width: 80%;
  height: 80%;
}

/* Add Animation */
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0)
  }

  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }


}

//
.container-form {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pickerDiv {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-Index: 2000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00000083;
}

.pickerDivContent {
  width: 250px;
  height: 250px;

}


.-filters .rt-tr .rt-th input {
  background-repeat: no-repeat;
  background-position-y: 5px;
  background-position-x: calc(100% - 2px);
  background-size: 20px 20px;
  background-image: url("../images/search.png");
}

.-filters .rt-tr .rt-th input:focus {
  background-color: white;
  background-image: none;
}

.form-control,
.css-1pcexqc-container {
  border: 1px solid #fad0ad;
  border-radius: 5px;
}

.css-bg1rzq-control,
.css-1hwfws3,
.css-16pqwjk-indicatorContainer {
  //background-color: #e0e0e0;

}

.userAssignForm {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}

.csv_form {
  display: flex;
  padding-Top: 5%;
  flex-direction: column;
  justify-content: center;
}

.editButton {
  -webkit-appearance: none !important;
  text-decoration: none;
}


//project card

.project_card_details {
  padding: 5px;
}

.project_card_nav {
  background-color: #ef412354;
  border-radius: 10px;
  padding: 10px;
}

.pc_tab {
  color: #ef4123;
  font-weight: 600;
  width: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 5px;
}

.pc_tab_active {
  background-color: #ef4123;
  width: 100px;
  color: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
}

.pc_tab_content {
  display: flex;

}

.pc_sub_tab {
  display: flex;
  flex-direction: column;
}

.pc_sub_tab_content {}

.pc_sub_user {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  box-shadow: 1px 1px 6px rgb(196, 196, 196)
}

.pc_sub_timeline {
  border-bottom: 1px solid gray;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}


.pc_sub_user:hover {
  box-shadow: 1px 1px 6px #fa6065;
  cursor: pointer;
}

.pc_sub_pic_image {}

.pc_sub_pic {
  width: 150px;
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 125px;
  // border-bottom-right-radius: 25px;
  overflow: hidden;
}

.pc_sub_userDetails {
  width: 100%;
  background-color: #ef4123;
}

.pc_sub_userDetails_content {
  width: 100%;
  background-color: #ffffff;
  padding: 5px;
  padding-left: 20px;
  border-bottom-right-radius: 100px;
}

.pc_sub_rate {
  width: 120px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ef4123;
}

.pc_email {
  color: #ef4123;
}

.pc_sub_user_main {
  height: 500px;
  overflow-y: auto;
  width: 100%;
  padding: 10px;
  -webkit-transition: width 2s;
  /* Safari prior 6.1 */
  transition: width 2s;
}

.pc_sub_user_main_hidden {
  width: 0px;
  overflow: hidden;
  -webkit-transition: width 2s;
  /* Safari prior 6.1 */
  transition: width 2s;
}

.pc_sub_user_main_timeView {
  height: 500px;
  overflow-y: auto;
  width: 100%;
  padding: 10px;
  -webkit-transition: width 2s;
  /* Safari prior 6.1 */
  transition: width 2s;
}

.pc_sub_user_main_hidden_timeView {
  width: 0px;
  overflow: hidden;
  -webkit-transition: width 2s;
  /* Safari prior 6.1 */
  transition: width 2s;
}

/*Task */
.task_card {
  height: 380px;
  width: 245px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-left: 30px;
  margin-bottom: 30px;
}

.task_card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.task_card_title {
  text-align: center;
  color: white;
}

.task_card_touch {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // background-color: gray;
}

.task_card_touch_description {
  height: 100px;
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;

}


.task_card_touch_description_content {
  width: 300px;
}

.task_card_touch_description_btn {
  width: 60px;
  cursor: pointer;
}

.task_card_reduce {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: rgb(146, 6, 6);
  display: flex;
  justify-content: center;
  padding-top: 10px;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.task-container {
  display: flex;
  flex-wrap: wrap;
}

.user_task_details {
  display: flex;
  flex-direction: row;
}

#header-shape-gradient {
  --color-stop: #f12c06;
  --color-bot: #faed34;
}

.findbar {
  padding-bottom: 20px;
}

.search_input {
  border-radius: 5px;
  width: 300px;
  height: 30px;
  box-shadow: 1px 1px 1px black;
}

.ActivitiesCardTitle {
  background-color: #dad6c9;
  padding: 5px;
  padding-left: 5px;
  width: 100%;
}

.home_dailyTask_card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #d12304
}


.home_dailyTask_card:hover {
  background-color: #f7be90;
}

.home_dailyTask_date {
  font-size: 12px;
  background-color: #d12304;
  color: white;
  padding: 2px;
  width: 100px;
  border-radius: 7px;
  text-align: center;
  height: 20px;
}

.home_dailyTask_card_content {
  display: flex;
  flex-direction: row;
}

.home_dailyTask_card_content_1 {
  width: 20%
}

.home_dailyTask_card_content_2 {
  width: 50%;
  margin-left: 10px;
}

.home_dailyTask_card_content_2 div {
  font-size: 12px;
}

.home_dailyTask_card_data {
  overflow-y: scroll;
  padding: 5px;
}

.home_dailyTask_content {
  width: 100%;
  -webkit-transition: width 1s;
  /* Safari prior 6.1 */
  transition: width 1s;

}

.home_dailyTask_content_hidden {
  width: 0%;
  overflow-x: scroll;
  -webkit-transition: width 1s;
  /* Safari prior 6.1 */
  transition: width 1s;

}

.home_dailyTask_content_task {
  width: 0%;
  min-height: 200px;
  overflow-x: scroll;
  -webkit-transition: width 1s;
  /* Safari prior 6.1 */
  transition: width 1s;

}

.home_dailyTask_content_task_hidden {
  width: 120%;
  -webkit-transition: width 1s;
  /* Safari prior 6.1 */
  transition: width 1s;
}

.home_back {
  font-size: 20px;
  font-weight: 700;
  color: red;
  cursor: pointer;
  text-align: end;
}

.task_card_home {
  background-color: white;
  overflow: hidden;
  padding: 10px;
  box-shadow: 1px 1px 2px 2px #b6b6b6;
}

.follower_card {
  background-color: white;
  padding: 10px;
  height: 600px;
  overflow-y: scroll;
  border: 10px solid #ffff;
  box-shadow: 0px 2px 2px 0px #b6b6b6;
}

.task_event_card {
  background-color: white;
  height: 85vh;
  padding: 10px;
  overflow-y: scroll;
}

.task_event_eventName {
  color: black;
  font-size: 14px;
  text-align: center;
}

.task_event_cardTab {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.task_event_cardTab_main {
  border-radius: 10px;
  padding: 5px;
  box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 4px;
  margin-top: 4px;
  display: flex;
  flex-direction: row-reverse;
}

.task_event_reduce {
  width: 35px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

}

.reduce_button_event {
  background-color: #a01c04;
  height: 30px;
  width: 30px;
  border-radius: 30px;
}

.task_event_Name {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.reduce_button_event_content {
  font-size: 15px;
  color: #ffffff;
  display: flex;
  font-size: 40px;
  flex-direction: row;
  justify-content: center;
  margin-top: -20px;
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.521);
  padding: 5px;
}

.date-picker-list {
  display: flex;
  height: 55px;
  overflow-x: scroll;
}

.date-picker {
  color: white;
  background: black;
  padding: 5px;
  min-width: 120px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid white;
  cursor: pointer;
}

.date-picker:hover {
  background: rgb(1, 10, 134);
}

.date-picker-active {
  background: #0070e9;
  color: white;
  width: 200px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid white;
  cursor: pointer;
}


.loader {
  width: 100px;
  height: 70px;
  margin: 50px auto;
  position: absolute;
  margin-top: -50px;
}

.loader span {
  display: block;
  width: 5px;
  height: 10px;
  background: #e43632;
  position: absolute;
  bottom: 0;
  animation: loading-1 2.25s infinite ease-in-out;
}

.loader span:nth-child(2) {
  left: 11px;
  animation-delay: .2s;
}

.loader span:nth-child(3) {
  left: 22px;
  animation-delay: .4s;
}

.loader span:nth-child(4) {
  left: 33px;
  animation-delay: .6s;
}

.loader span:nth-child(5) {
  left: 44px;
  animation-delay: .8s;
}

.loader span:nth-child(6) {
  left: 55px;
  animation-delay: 1s;
}

.loader span:nth-child(7) {
  left: 66px;
  animation-delay: 1.2s;
}

.loader span:nth-child(8) {
  left: 77px;
  animation-delay: 1.4s;
}

.loader span:nth-child(9) {
  left: 88px;
  animation-delay: 1.6s;
}

@-webkit-keyframes loading-1 {
  0% {
    height: 10px;
    transform: translateY(0px);
    background: #ff4d80;
  }

  25% {
    height: 60px;
    transform: translateY(15px);
    background: #02000a;
  }

  50% {
    height: 10px;
    transform: translateY(-10px);
    background: #e29013;
  }

  100% {
    height: 10px;
    transform: translateY(0px);
    background: #e50926;
  }
}

@keyframes loading-1 {
  0% {
    height: 10px;
    transform: translateY(0px);
    background: #ff4d80;
  }

  25% {
    height: 60px;
    transform: translateY(15px);
    background: #000000;
  }

  50% {
    height: 10px;
    transform: translateY(-10px);
    background: #e29013;
  }

  100% {
    height: 10px;
    transform: translateY(0px);
    background: #e50926;
  }
}

.loaderMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff99;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10000;
  margin-top: -50px;
}

.loaderContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.datePickerMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #00000099;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10000;
  margin-top: -45px;
}

.datePickerContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.datePickerCard {
  background-color: #000000;
  height: 300px;
  width: 500px;
}

.btn-pro {
  background-color: #d12304;
  color: white;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: 120px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.btn-pro-excel {
  background-color: #0664bd;
  color: white;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: 120px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

$transition-easing: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$bounce-easing: cubic-bezier(0.175, 0.885, 0.320, 1.275);

.datePicker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  margin-top: 40px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid black;
  z-index: 3000;
  padding: 5px;
  transition: width 0.8s $transition-easing,
    height 0.8s $transition-easing,
    transform 0.8sn $bounce-easing;
}

.mfp-move-from-top {

  .mfp-content {
    vertical-align: top;
  }

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 2s;

    transform: translateY(-100px);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.2s;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateY(0);
    }

    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: translateY(-50px);
      opacity: 0;
    }

    &.mfp-bg {
      opacity: 0;
    }

  }

}


.datePickerButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

//loader
.cssload-thecube {
  width: 67px;
  height: 67px;
  margin: 0 auto;
  margin-top: 45px;
  position: relative;
  // transform: rotateZ(45deg);
  // 	-o-transform: rotateZ(45deg);
  // 	-ms-transform: rotateZ(45deg);
  // 	-webkit-transform: rotateZ(45deg);
  // 	-moz-transform: rotateZ(45deg);
}

.cssload-thecube .cssload-cube {
  position: relative;
  transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
}

.cssload-thecube .cssload-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.cssload-round {
  background-color: #d12304;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.cssload-square {
  background-color: #d12304;
  width: 80%;
  height: 100%;
}

.cssload-cube {
  padding: 5px;
}

.cssload-c1 {
  padding-left: 10%;
}

.cssload-thecube .cssload-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.92);
  animation: cssload-fold-thecube 2.76s infinite linear both;
  -o-animation: cssload-fold-thecube 2.76s infinite linear both;
  -ms-animation: cssload-fold-thecube 2.76s infinite linear both;
  -webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
  -moz-animation: cssload-fold-thecube 2.76s infinite linear both;
  transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
}



.cssload-thecube .cssload-c2 {
  transform: scale(1.1) rotateZ(90deg);
  -o-transform: scale(1.1) rotateZ(90deg);
  -ms-transform: scale(1.1) rotateZ(90deg);
  -webkit-transform: scale(1.1) rotateZ(90deg);
  -moz-transform: scale(1.1) rotateZ(90deg);
}

.cssload-thecube .cssload-c3 {
  transform: scale(1.1) rotateZ(180deg);
  -o-transform: scale(1.1) rotateZ(180deg);
  -ms-transform: scale(1.1) rotateZ(180deg);
  -webkit-transform: scale(1.1) rotateZ(180deg);
  -moz-transform: scale(1.1) rotateZ(180deg);
}

.cssload-thecube .cssload-c4 {
  transform: scale(1.1) rotateZ(270deg);
  -o-transform: scale(1.1) rotateZ(270deg);
  -ms-transform: scale(1.1) rotateZ(270deg);
  -webkit-transform: scale(1.1) rotateZ(270deg);
  -moz-transform: scale(1.1) rotateZ(270deg);
}

.cssload-thecube .cssload-c2:before {
  animation-delay: 0.35s;
  -o-animation-delay: 0.35s;
  -ms-animation-delay: 0.35s;
  -webkit-animation-delay: 0.35s;
  -moz-animation-delay: 0.35s;
}

.cssload-thecube .cssload-c3:before {
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s;
}

.cssload-thecube .cssload-c4:before {
  animation-delay: 1.04s;
  -o-animation-delay: 1.04s;
  -ms-animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  -moz-animation-delay: 1.04s;
}



@keyframes cssload-fold-thecube {

  0%,
  10% {
    transform: perspective(126px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(126px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    transform: perspective(126px) rotateY(180deg);
    opacity: 0;
  }
}

@-o-keyframes cssload-fold-thecube {

  0%,
  10% {
    -o-transform: perspective(126px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -o-transform: perspective(126px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -o-transform: perspective(126px) rotateY(180deg);
    opacity: 0;
  }
}

@-ms-keyframes cssload-fold-thecube {

  0%,
  10% {
    -ms-transform: perspective(126px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -ms-transform: perspective(126px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -ms-transform: perspective(126px) rotateY(180deg);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-fold-thecube {

  0%,
  10% {
    -webkit-transform: perspective(126px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(126px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(126px) rotateY(180deg);
    opacity: 0;
  }
}

@-moz-keyframes cssload-fold-thecube {

  0%,
  10% {
    -moz-transform: perspective(126px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -moz-transform: perspective(126px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -moz-transform: perspective(126px) rotateY(180deg);
    opacity: 0;
  }
}

.buttonRight {
  display: flex;
  flex-direction: row-reverse;
}

.ant-picker-content th {
  background-color: white !important;
}

.noData {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}