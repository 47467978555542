.RecentActivities {
    box-shadow: 0 4px 8px 0 rgba(219, 94, 11, 0.2);
    border: 1px solid rgba(219, 94, 11, 0.2);
    transition: 0.3s;
    margin-bottom: 5px;
    background-color: white;
    width: 100%;

}

.RecentActivities_card {

    padding: 10px;
    height: 310px;
    overflow-y: scroll;
    width: 100%;
}

/* On mouse-over, add a deeper shadow */
.RecentActivities:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(219, 94, 11, 0.2);
}


.RecentActivities_card_tab {
    border: 1px solid rgba(219, 94, 11, 0.2);
    border-radius: 5px;
    padding: 1px;
    margin-bottom: 3px;
}

.RecentActivities_card_tab:hover {
    box-shadow: 0 1px 2px 0 #ef4123;
    margin-top: -5px;

}

.RecentActivities_card_tab_Title {
    font-size: 15px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
}

.RecentActivities_card_tab_date {
    font-size: 12px;
    color: rgb(80, 79, 79);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;

}

.RecentActivities_card_tab_content {
    display: flex;
    flex-direction: row;
    text-align: center;
}

.RecentActivities_card_tab_tap {
    width: 30%;
}

.RecentActivities_card_tab_tap_title {
    font-size: 12px;
    color: rgb(163, 161, 161);
    margin-bottom: 1px !important;

}

.RecentActivities_card_tab_tap h4 {
    font-size: 12px;
    color: rgb(163, 161, 161);
    margin-bottom: 1px !important;

}