@font-face {
  font-family: "Helvetica Neue";
  src: url('./fonts/Pragmatica-ExtraLight.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  font-size: 14px;
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}