.rt-table {
    width: 100%;
}

.rt-thead {
    background-color: rgb(0, 0, 0);
    padding: 10px;
    border-radius: 10px;
}

.rt-thead th {
    color: white;
    padding: 10px;
    padding-left: 20px;
    border-left: 1px solid gray;
}

.rt-tbody td {
    padding: 5px;
    padding: 10px;
    padding-left: 20px;
    border-bottom: 1px solid rgb(197, 197, 197);
}

.rt-pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rt-pagination button {
    width: 100px;
}

.ant-btn-primary.rt-Button {
    background-color: black;
    color: white;
}

.ant-btn-primary.rt-Button:hover,
.ant-btn-primary.rt-Button:focus {
    color: #fff;
    background: #6d000f !important;
    border-color: #6d000f !important;
}

.ant-btn-primary.rt-disbledButton {
    background-color: rgb(134, 134, 134);
    color: white;

}


.rt-odd {
    background-color: rgb(224, 223, 223);
}