#components-layout-demo-fixed .logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 24px 16px 0;
    float: left;
}

.site-layout .site-layout-background {
    background: #fff;
}

.card-container p {
    margin: 0;
}

.card-container>.ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
}

.card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
}

.card-container>.ant-tabs-card>.ant-tabs-nav::before {
    display: none;
}

.card-container>.ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    // background: transparent;
}

.card-container>.ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab-active {
    border-color: #fff;
    background: rgb(19, 19, 19);
}

#components-tabs-demo-card-top .code-box-demo {
    background: #f5f5f5;
    overflow: hidden;
    padding: 24px;
}

[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
    border-radius: 5px !important;
    border: 1px solid black !important;
}

[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
    background: #141414;
}



.card-container>.ant-tabs-card .ant-tabs-tab-active {
    border-color: #141414;
    background: #141414 !important;
    border-radius: 5px !important;
}


.card-container>.ant-tabs-card .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
}

#role .ant-tabs-tab {
    min-width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}



table thead th {
    background-color: black !important;
}

table thead th span {
    color: white;
}

table {
    padding-top: 10px;
}

.ant-tabs-tab {
    border-top: 2px solid black !important;
    border-left: 2px solid black !important;
    border-right: 2px solid black !important;
}