.ongoingCard {
    display: flex;
    padding: 5px;
}

.ongoingCardDeplayed {
    display: flex;
    padding: 20px;
}

.card-values {
    color: white;
    font-weight: 900;
    align-content: center;
}

.card-values-delay {
    color: black;
    font-weight: 900;
}

.card-presentage {
    width: 100%;
    height: 100%;
    display: grid;
    color: white;
    justify-content: center;
    align-content: center;
}

.cardTime h3 {
    font-weight: 900;

}

.cardTime h5 {
    color: black !important;
}

//close button create for datepicker
.close_button {
    background-color: #ed1c24;
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    -webkit-box-shadow: 1px 2px 1px black;
    -moz-box-shadow: 1px 2px 1px black;
    box-shadow: 1px 2px 1px black;

    &:hover {
        background-color: #bd080e;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        margin-left: -5px;
        margin-top: 0px;
        transform: scale(1.05, 1.05);
    }
}


.close_button span {
    color: white;
    font-size: large;
    width: 100%;
    text-align: center;
    font-weight: 800;

}

.datePicker {
    -webkit-box-shadow: 2px 3px 2px black;
    -moz-box-shadow: 2px 3px 2px black;
    box-shadow: 2px 3px 2px black;
}

.card-presentage-delay {
    width: 100px;
    height: 100%;
    border-radius: 10px;
    display: grid;
    color: white;
    background-color: #ed1c24;
    justify-content: center;
    align-content: center;
}

.chart_cards {
    padding: 10px;
}

.chart_card_content {
    background-color: white;
    padding: 10px;
    overflow: auto;
}

.chart_card_content_warning {
    background-color: white;
    padding: 20px;
    overflow-y: auto;
    height: 320px;
    font-size: 12px;
}

.chart_card_content_warning_header {
    background-color: rgb(222, 0, 0);
}



.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar-thumb {
    background-color: #000000;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
    background-color: #000000;
}


.projectCard {}

.projectCardHeader {
    width: 100%;
    height: 38px;
    border-radius: 7px;
    background-color: #ef4123;
    padding-left: 10px;
    display: flex;
    color: white;
    align-self: center;
    padding-top: 5px
}

.cardTime {
    border-left-width: 10px;
    border-left-style: solid;
    margin: 5px;
    padding: 2px;
    padding-left: 5px;
}

svg {
    overflow: visible !important;
}


html {
    scroll-behavior: smooth;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
}

.Selectable .DayPicker-Day {
    border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}