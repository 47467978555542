.project_card {
    height: 380px;
    width: 350px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0px !important;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: white;
}

.support_card {
    height: 240px;
    width: 250px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0px !important;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: white;
}

.project_card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

}


.project_card_image {
    width: 350px;
    height: 150px;
}

.project_card_image_tab {
    height: 80px;
    overflow: hidden;
}

.project_card_log_tab {
    height: 30px;
    padding-top: 3px;
    padding-left: 3px;
}

.project_card_log {
    height: 80px;
    background-color: rgb(255, 255, 255);
    width: 80px;
    overflow: hidden;
    border-radius: 80px;
    position: absolute;
    margin-top: -50px;
    margin-left: 97px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.project_card_header_tab {
    height: 160px;
    border-bottom: 2px solid rgb(207, 205, 205);
    padding: 10px;
    overflow: hidden;
}

.project_card_header_header {
    font-weight: 700;
    font-size: 13px;
    text-align: center;
}

.project_card_header_decription {
    font-weight: 300;
    font-size: 13px;
    text-align: center;
}

.project_card_values_header {
    padding-top: 3px;
    padding-bottom: 0px;
    font-size: 12px;
    margin-bottom: -9px;
    text-align: center;
}

.project_card_values_value {
    padding: 5px;
    font-size: 12px;
    font-weight: 700;
    text-align: center;

}

.project_card_values_content {
    display: flex;
    flex-direction: column;
    font-style: unset;
}

.btn_project_card {
    height: 30px;
    -moz-box-shadow: inset 0 0 5px #868686;
    -webkit-box-shadow: inset 0 0 5px #868686;
    box-shadow: inset 0 0 5px #868686;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding: 0px;
    font-size: 12px;
    color: white;
    background-color: #737271;
    border: 1px solid white;
}

.btn_project_card:hover {
    -moz-box-shadow: inset 0 0 5px #006eff;
    -webkit-box-shadow: inset 0 0 5px #006eff;
    box-shadow: inset 0 0 5px #006eff;
    background-color: #88b1ff;
    color: black;
}

.project_image {
    height: 80px;
    width: 80px;
}


.project_card_image_tab_edit {
    position: absolute;
    padding: 2px;
    padding-left: 7px;
    margin-top: 4px;
    margin-left: 315px;
    padding-right: 7px;
    -moz-box-shadow: inset 0 0 5px #868686;
    -webkit-box-shadow: inset 0 0 5px #868686;
    box-shadow: inset 0 0 5px #868686;
    background-color: white;
    border-radius: 5px;
}

.project_card_status_active {
    background-color: green;
    width: 70px;
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 7px;
    margin-bottom: 2px;
}

.project_card_status_inactive {
    background-color: rgb(192, 192, 192);
    width: 70px;
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 7px;
    margin-bottom: 2px;
    cursor: pointer;

}

.project_card_status_completed {
    background-color: rgb(167, 1, 1);
    width: 70px;
    color: white;
    font-size: 12px;
    text-align: center;
    margin-bottom: 2px;
    border-radius: 7px;
    cursor: pointer;
}